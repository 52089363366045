<template>
  <div class="ChatHelpModels">
    <p class="help-title">
      {{ msg.fromUser.user === profileUser.user ? "Mis modelos" : `Modelos de: ${msg.fromUser.user}` }}
    </p>
    <ChatHelpModel v-for="model in msg.currentModels" :key="model._id" :id="model.id" :msg="msg" :model="model" />
  </div>
</template>

<script>
export default {
  components: {
    ChatHelpModel: () => import("./ChatHelpModel.vue"),
  },
  props: ["msg"],
  computed: {
    profileUser() {
      return this.$store.state.user.profile;
    },
  },
};
</script>

<style lang="scss">
.ChatHelpModels {
  width: 90%;
  margin: 0 auto;
  background: #f3f3f3;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;

  .help-title {
    font-size: 13px;
    color: #bd0909;
    font-weight: 600;
  }
}
</style>
